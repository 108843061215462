import React from "react";
import { WideContainer } from "./container";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import { baseHeading } from "./typography";
import { OutlineButtonInner } from "./button";
import { FooterQuery } from "../../graphql-types";

export const Footer: React.FC = () => {
  const data = useStaticQuery<FooterQuery>(graphql`
    query Footer {
      wp {
        globaal {
          globalCustomFields {
            address
            email
            openingHours
          }
        }
      }
    }
  `);

  return (
    <WideContainer as="footer">
      <InformationBoxes>
        <InformationBox>
          <InformationBoxHeading>Offerte?</InformationBoxHeading>
          <InformationBoxContent style={{ maxWidth: "20rem" }}>
            Heeft u een vraag of wilt u contact voor een vrijblijvende offerte?
            Dat kan!
            <div style={{ marginTop: 40 }}>
              <Link to="/contact/">
                <OutlineButtonInner>
                  Offerte aanvragen &rarr;
                </OutlineButtonInner>
              </Link>
            </div>
          </InformationBoxContent>
        </InformationBox>

        <InformationBox>
          <InformationBoxHeading>Locatie</InformationBoxHeading>
          <InformationBoxContent
            dangerouslySetInnerHTML={{
              __html: data.wp?.globaal?.globalCustomFields?.address || "",
            }}
          />
        </InformationBox>

        <OpeningHoursInformationBox>
          <InformationBoxHeading>Openingstijden</InformationBoxHeading>
          <InformationBoxContent
            dangerouslySetInnerHTML={{
              __html: data.wp?.globaal?.globalCustomFields?.openingHours || "",
            }}
          />
        </OpeningHoursInformationBox>
      </InformationBoxes>

      <FooterFooter>
        <Copyright>
          Copyright &copy; Deco Art {new Date().getFullYear()}
        </Copyright>
        <Link to="/voorwaarden/" style={{ flexGrow: 1 }}>
          Voorwaarden
        </Link>
        <Credits>
          <a href="https://www.webba.nl" target="_blank">
            Website: Webba
          </a>
        </Credits>
      </FooterFooter>
    </WideContainer>
  );
};

const InformationBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 80px;
`;

const InformationBox = styled.div`
  width: 100%;
  margin-bottom: 50px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: auto;
  }
`;

const OpeningHoursInformationBox = styled(InformationBox)`
  position: relative;
  padding: 30px 30px;
  color: #fff;
  background-color: #00a88e;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-right: 80px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    top: -20px;
  }
`;

const InformationBoxHeading = styled.h2`
  ${baseHeading}
  margin-top: 0px;
  margin-bottom: 0.75em;
`;

const InformationBoxContent = styled.div``;

const FooterFooter = styled.div`
  margin-bottom: 75px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    text-align: left;
    border-top: 1px solid #ededed;
    padding-top: 25px;
    margin-top: 50px;
    display: flex;
  }
`;

const Copyright = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 30px;
  }
`;

const Credits = styled.div`
  margin-top: 40px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 0;
  }
`;
