import styled from "styled-components";

export const OutlineButtonInner = styled.span`
  border: 1px solid #e3e3e3;
  color: #00a88e;
  padding: 12px 30px;
  border-radius: 30px;
  cursor: pointer;
  transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    background-color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    border-color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover {
    background-color: #00a88e;
    color: #fff;
    border-color: #00a88e;
  }
`;
