import styled from "styled-components";

/**
 * Base container
 */

const BaseContainer = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
`;

/**
 * Narrow container
 */

export const NarrowContainer = styled(BaseContainer)`
  max-width: 900px;
`;

/**
 * Wide container
 */

export const WideContainer = styled(BaseContainer)`
  max-width: 1200px;
`;
