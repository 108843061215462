import React from "react";
import styled from "styled-components";
import ReactResizeDetector from "react-resize-detector";

// @ts-ignore
import logo from "../assets/logo.svg";
// @ts-ignore
import hamburger from "../assets/hamburger.svg";
// @ts-ignore
import close from "../assets/close.svg";
// @ts-ignore
import phone from "../assets/phone.svg";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Location } from "@reach/router";
import { NavbarQuery } from "../../graphql-types";

type NavbarProps = { phoneNumber: string };

export const Navbar: React.FC<NavbarProps> = (props) => {
  const menuItems = useStaticQuery<NavbarQuery>(graphql`
    query Navbar {
      allWpMenuItem(filter: { parentId: { eq: null } }) {
        edges {
          node {
            label
            url
            childItems {
              nodes {
                label
                url
              }
            }
          }
        }
      }
    }
  `);

  const { phoneNumber } = props;

  const [isExpanded, updateIsExpanded] = React.useState(false);

  const toggleable = (
    <Sticky style={{ position: "sticky", top: 0, zIndex: 99 }}>
      <div
        style={{
          position: "relative",
          zIndex: 999,
          display: "flex",
          backgroundColor: "#000",
          height: 60,
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 10,
          paddingRight: 25,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            height: "100%",
            transform: `translateX(${isExpanded ? "-150%" : 0})`,
            transition:
              "transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955)",
          }}
        >
          <Link to="/">
            <img src={logo} style={{ maxHeight: "100%" }} />
          </Link>
        </div>

        <div>
          {isExpanded ? (
            <button
              onClick={() => updateIsExpanded(false)}
              style={{ display: "flex", alignItems: "center", outline: "none" }}
            >
              <img src={close} style={{ height: 23 }} />
            </button>
          ) : (
            <button
              onClick={() => updateIsExpanded(true)}
              style={{ display: "flex", alignItems: "center", outline: "none" }}
            >
              <img src={hamburger} style={{ height: 18 }} />
            </button>
          )}
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          backgroundColor: "#000",
          height: typeof window !== "undefined" ? window.innerHeight - 60 : 0,
          width: "100vw",
          transform: `scale(${isExpanded ? 1 : 0}) translateY(${
            isExpanded ? 0 : "-100%"
          })`,
          transformOrigin: "top right",
          transition: "transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop:
            typeof window !== "undefined" ? window.innerHeight / 12 : 0,
          paddingBottom:
            typeof window !== "undefined" ? window.innerHeight / 12 : 0,
          overflowY: "auto",
        }}
      >
        <ul style={{ width: "100%" }}>
          {menuItems.allWpMenuItem.edges.map(({ node: menuItem }, i) => (
            <li key={i} style={{ width: "100%", textAlign: "center" }}>
              {(menuItem.childItems?.nodes?.length || 0) > 0 ? (
                <Submenu menuItem={menuItem} />
              ) : (
                <Link
                  to={menuItem.url || ""}
                  style={{
                    display: "block",
                    padding: 15,
                    color: "#fff",
                    fontSize: 23,
                    fontWeight: 700,
                  }}
                  activeStyle={{ color: "#00a88e" }}
                >
                  {menuItem.label}
                </Link>
              )}
            </li>
          ))}
        </ul>

        <div>
          <a
            href={`tel:${phoneNumber}`}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#00a88e",
              borderRadius: 55,
              height: 55,
              padding: "0 25px",
              color: "#fff",
              fontWeight: 500,
              fontSize: 16,
            }}
          >
            <img src={phone} style={{ marginRight: 10, marginTop: 3 }} />{" "}
            {phoneNumber}
          </a>
        </div>
      </div>
    </Sticky>
  );

  const nonToggleable = (
    <Sticky
      style={{
        position: "sticky",
        top: 0,
        zIndex: 999,
        display: "flex",
        backgroundColor: "#000", // todo: should be equal for both toggleable and non-toggleable
        height: 100,
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 15,
        paddingRight: 40,
        paddingTop: 15,
        paddingBottom: 15,
      }}
    >
      <div style={{ height: "100%" }}>
        <Link to="/">
          <img src={logo} style={{ maxHeight: "100%" }} />
        </Link>
      </div>

      <ul style={{ display: "flex", alignItems: "center " }}>
        {menuItems.allWpMenuItem.edges.map(({ node: menuItem }, i) => (
          <li
            key={i}
            style={{
              marginLeft: i === 0 ? 0 : "1.5vw",
              marginRight:
                i === menuItems.allWpMenuItem.edges.length - 1 ? 0 : "1.5vw",
            }}
          >
            {(menuItem.childItems?.nodes?.length || 0) > 0 ? (
              <Dropdown>
                <Location>
                  {({ location }) => (
                    <DropdownLabel
                      style={{
                        // sorry!
                        color: location.pathname.includes("diensten")
                          ? "#00a88e"
                          : "#fff",
                        borderBottom: location.pathname.includes("diensten")
                          ? "2px solid #00a88e"
                          : "none",
                      }}
                    >
                      {menuItem?.label}
                    </DropdownLabel>
                  )}
                </Location>

                <DropdownListWrapper>
                  <ul
                    style={{
                      backgroundColor: "#fff",
                      boxShadow:
                        "0 14px 28px rgba(0, 0, 0, 0.10), 0 10px 10px rgba(0, 0, 0, 0.15)",
                      paddingTop: 10,
                      paddingBottom: 20,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    {menuItem.childItems?.nodes?.map((child, i) => (
                      <li key={i}>
                        <DropdownLink
                          to={child?.url || ""}
                          activeStyle={{ color: "#00a88e" }}
                        >
                          {child?.label}
                        </DropdownLink>
                      </li>
                    ))}
                  </ul>
                </DropdownListWrapper>
              </Dropdown>
            ) : (
              <NavLink
                to={menuItem.url || ""}
                activeStyle={{
                  color: "#00a88e",
                  borderBottom: "2px solid #00a88e",
                }}
              >
                {menuItem.label}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </Sticky>
  );

  return (
    <ReactResizeDetector handleWidth>
      {({ width }: any) => (width < 1024 ? toggleable : nonToggleable)}
    </ReactResizeDetector>
  );
};

const Sticky = styled.nav`
  position: sticky;
  position: -webkit-sticky;
`;

const DropdownListWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  left: -60px;
  padding-top: 20px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;

const DropdownLabel = styled.span`
  font-size: 19px;
  font-weight: 700;
  padding-bottom: 10px;
  transition: color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  cursor: pointer;

  &:hover {
    color: #00a88e !important;
  }
`;

const Dropdown = styled.div`
  position: relative;

  :hover ${DropdownListWrapper} {
    visibility: visible;
    opacity: 1;
  }

  :hover ${DropdownLabel} {
    color: #00a88e !important;
  }
`;

const NavLink = styled(Link)`
  color: #fff;
  font-size: 19px;
  font-weight: 700;
  padding-bottom: 10px;
  transition: color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover {
    color: #00a88e;
  }
`;

const DropdownLink = styled(Link)`
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 19px;
  color: #0b0b0b;
  padding-left: 10px;
  padding-right: 120px;
  line-height: 46px;
  transition: transform 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);

  :hover {
    transform: translateX(5px);
    color: #00a88e !important;
  }

  /* styles if active: */
  /* border-left: 2px solid #00a88e; */
  /* color: #00a88e */
`;

type SubmenuProps = {
  menuItem: NavbarQuery["allWpMenuItem"]["edges"][0]["node"];
};

const Submenu: React.FC<SubmenuProps> = (props) => {
  const { menuItem } = props;

  const [isExpanded, updateIsExpanded] = React.useState(false);

  return (
    <>
      <Location>
        {({ location }) => (
          <button
            style={{
              width: "calc(100% - 23px)",
              marginLeft: 23,
              padding: 15,
              // sorry!
              color: location.pathname.includes("diensten")
                ? "#00a88e"
                : "#fff",
              fontSize: 23,
              fontWeight: 700,
              outline: "none",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
            }}
            onClick={() => updateIsExpanded(!isExpanded)}
          >
            {menuItem.label}
            <div
              style={{
                width: 0,
                height: 0,
                borderLeft: "8px solid transparent",
                borderRight: "8px solid transparent",
                borderTop: "10px solid #00a88e",
                marginLeft: 15,
                transform: `rotate(${isExpanded ? -180 : 0}deg)`,
                transition:
                  "transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955)",
              }}
            />
          </button>
        )}
      </Location>

      <ul
        style={{
          backgroundColor: "#444",
          boxShadow: "inset 0 0 2px #000000",
          overflow: "hidden",
          display: isExpanded ? "block" : "none",
        }}
      >
        {menuItem.childItems?.nodes?.map((child, i) => (
          <li key={i}>
            <Link
              style={{
                display: "block",
                color: "#aaa",
                fontSize: 18,
                fontWeight: 700,
                padding: 5,
              }}
              activeStyle={{ color: "#fff" }}
              to={child?.url || ""}
            >
              {child?.label}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};
