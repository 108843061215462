import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "../global-style";
import { Navbar } from "./navbar";
import Helmet from "react-helmet";
import { theme } from "../theme";
import { graphql, useStaticQuery } from "gatsby";
import { Footer } from "./footer";

export const Layout: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />

        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Poppins:300&display=swap"
            rel="stylesheet"
          />
        </Helmet>

        <Navbar phoneNumber="32 (0)3 541 08 89" />

        {/* content */}
        <div>{children}</div>

        <Footer />
      </>
    </ThemeProvider>
  );
};
